<!--  -->
<template>
  <div class=''>
    <div style="font-size: 35px">账户信息</div>
    <el-divider></el-divider>
    <el-descriptions class="margin-top"  :column="2"  border>

      <el-descriptions-item><template slot="label"><i class="el-icon-user"></i>
        用户名</template>赵主任</el-descriptions-item>

      <el-descriptions-item><template slot="label"><i class="el-icon-office-building"></i>
          小屋名称</template>  </el-descriptions-item>

      <el-descriptions-item><template slot="label"><i class="el-icon-location-outline"></i>
          小屋地址</template>  </el-descriptions-item>

      <el-descriptions-item><template slot="label"><i class="el-icon-key"></i>
          小屋编码</template>  </el-descriptions-item>

      <el-descriptions-item><template slot="label"><i class="el-icon-phone"></i>
          联系电话</template>13482528734</el-descriptions-item>

      <el-descriptions-item><template slot="label"><i class="el-icon-message"></i>
          联系邮箱</template>xxxxx@xxxx.com</el-descriptions-item>

      <el-descriptions-item><template slot="label"><i class="el-icon-date"></i>
          创建时间</template>  </el-descriptions-item>

      <el-descriptions-item><template slot="label"><i class="el-icon-user-solid"></i>
          负责人</template>  </el-descriptions-item>

      <el-descriptions-item><template slot="label"><i class="el-icon-date"></i>
          小屋简介</template>（小屋介绍为空，请尽快写入）</el-descriptions-item>
    </el-descriptions>

    <el-button style="margin-top: 15px" type="primary" @click="dialogVisible = true">修改信息</el-button>
    <el-dialog
        title="账户信息修改"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose">

      <div style="font-size: 15px">用户名：
        <el-input style="width: 250px;margin-left: 30px;margin-top: 10px"
                  placeholder="赵主任"
                  v-model="input1"
                  :disabled="true">
        </el-input></div>
      <div style="font-size: 15px">小屋名称：
        <el-input style="width: 250px;margin-left: 15px;margin-top: 10px"
                  placeholder="请输入内容"
                  v-model="input2"
                  clearable>
        </el-input></div>
      <div style="font-size: 15px">小屋地址：
        <el-input style="width: 250px;margin-left: 15px;margin-top: 10px"
                  placeholder="请输入内容"
                  v-model="input3"
                  clearable>
        </el-input></div>
      <div style="font-size: 15px">小屋编码：
        <el-input style="width: 250px;margin-left: 15px;margin-top: 10px"
                  placeholder="请输入内容"
                  v-model="input4"
                  clearable>
        </el-input></div>
      <div style="font-size: 15px">联系电话：
        <el-input style="width: 250px;margin-left: 15px;margin-top: 10px"
                  placeholder="13482528734"
                  v-model="input5"
                  clearable>
        </el-input></div>
      <div style="font-size: 15px">联系邮箱：
        <el-input style="width: 250px;margin-left: 15px;margin-top: 10px"
                  placeholder="xxxxx@xxxx.com"
                  v-model="input6"
                  clearable>
        </el-input></div>
      <div style="font-size: 15px">创建时间：
<!--        <el-input style="width: 250px;margin-left: 15px;margin-top: 10px"
                  placeholder="请输入内容"
                  v-model="input7"
                  clearable>
        </el-input>-->
        <el-date-picker style="width: 250px;margin-left: 15px;margin-top: 10px"
            v-model="input7"
            type="date"
            placeholder="选择日期">
        </el-date-picker></div>
      <div style="font-size: 15px">负责人：
        <el-input style="width: 250px;margin-left: 30px;margin-top: 10px"
                  placeholder="请输入内容"
                  v-model="input8"
                  clearable>
        </el-input></div>
      <div style="font-size: 15px">小屋简介：
        <el-input style="width: 250px;margin-left: 15px;margin-top: 10px"
                  placeholder="请输入内容"
                  v-model="input9"
                  clearable>
        </el-input></div>



      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>


  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      dialogVisible: false,
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      input6: '',
      input7: '',
      input8: '',
      input9: '',
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          // eslint-disable-next-line no-unused-vars
          .then(_ => {
            done();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(_ => {});
    }
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
